import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pixel',
  templateUrl: './pixel.component.html',
  styleUrls: ['./pixel.component.css']
})
export class PixelComponent implements OnInit {
  pixelRotate: number;
  pixelY: number = window.outerHeight + 100;
  pixelX: number = Math.random() * window.document.body.offsetWidth;
  pixelContent: string;
  constructor(private ref: ChangeDetectorRef) {}


  ngOnInit() {
    this.pixelContent = Math.random() > 0.5 ? 'I' : 'O';
    this.pixelRotate = 0;
    setInterval(() => { this.updatePixel(); this.ref.markForCheck();
    }, Math.random() * 100);  }

  updatePixel() {
    this.pixelRotate++;
    if (this.pixelY > 0) {
      this.pixelY -= 1;
    } else {
      this.pixelY = window.outerHeight;
      this.pixelX = Math.random() * window.innerHeight;
    }
  }


}
