import { Component, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnChanges, OnInit {
  tts: SpeechSynthesisUtterance;
  pixelsCount: any = new Array(20);
  ngOnInit() {
    window.document.querySelector('video').style.display = 'block';
    // window.document.querySelector('source').style.display = 'block';
    window.document.querySelector('video').style.visibility = 'visible';
    // window.document.querySelector('source').style.visibility = 'visible';

    this.tts = new SpeechSynthesisUtterance();
    this.tts.text = 'Willkommen .';

    speechSynthesis.speak(this.tts);

    this.tts.text = 'Wie können wir Ihnen helfen ?';
    speechSynthesis.speak(this.tts);
  }

  public cancel() {
    speechSynthesis.pause();
    speechSynthesis.cancel();
  }

  public speak(event) {
    // console.log('speak', event.target.innerText);
    this.tts.text = event.target.innerText;
    speechSynthesis.speak(this.tts);
  }

  isPortraitOrientation() {
    // console.log('outerWidth', window.outerWidth, 'outerHeight', window.outerHeight);
    return window.outerWidth > window.outerHeight ? false : true;
  }

  ngOnChanges() {
    this.isPortraitOrientation();
  }

  // TODO change orientation trigger/Listner
  triggerChange() {
    this.isPortraitOrientation();
  }
}
