import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PixelComponent } from './pixel.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PixelComponent],
  exports: [PixelComponent]
})
export class PixelModule { }
